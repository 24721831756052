<template>
  <v-container>
    <v-alert type="error" outlined v-model='error' transition="scale-transition" text> {{errorMessage}}</v-alert>
    <v-row class="mb-1">
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" align="center">
        <v-avatar size="150" class="mt-8 mb-4">
          <v-img
            :src="User.ImageUrl || require('../assets/profileLogo.png')"
            aspect-ratio="1.0"
          >
          <v-btn fab class="ml-12 mt-12" color="primary"
           outlined @click="imageDialog = true;" v-if="edit"
           style="background-color: rgba(0,0,0,0.6); ">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          </v-img>
        </v-avatar>
        <br />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="8" lg="9" xl="10">
        <v-text-field v-model="AccountUsername" disabled label="Username">
        </v-text-field>
        <v-text-field v-model="User.Username" :disabled="!edit" label="Utente">
        </v-text-field>
        <v-btn class="mx-2 my-2"  outlined v-if="!edit && Users.length >= 2" color="primary" @click="deleteDialog = true;"
        ><v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" v-if="!edit" color="primary" outlined
        @click="edit = true; before = JSON.parse(JSON.stringify(User)); image = User.ImageUrl;"
        >Modifica Utente
        </v-btn>
        <v-btn class="mx-2 my-2" v-if="!edit" color="primary" @click="userDialog = true;"
        >Cambia Utente
        </v-btn>
        <v-checkbox v-if="edit" label="Cambia Password" v-model="changePassword"
        ></v-checkbox>
        <v-alert v-if="changePassword" type="warning" dense outlined transition="scale-transition" text> La modifica della password si riferisce alla password dell' account <b>{{AccountUsername}} e non agli utenti ad esso connessi</b> </v-alert>
        <v-text-field v-if="changePassword" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        autocomplete="new-password"
        @click:append="show1 = !show1"
         v-model="password" label="Nuova Password"
        >
        </v-text-field>
        <v-text-field v-if="changePassword" v-model="confirmPassword" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        autocomplete="new-password"
        @click:append="show2 = !show2"
        label="Ripeti Nuova Password"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="edit" class="mb-2">
      <v-spacer></v-spacer>
      <v-col>
        <v-btn color="primary" outlined 
          @click="editRollback()"
        >
          Annulla
        </v-btn>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="updateProfile()"> Salva Modifiche </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="imageDialog" persistent :max-width="dialogWidth">
      <v-card>
        <v-card-title class="text-h5"> Cambia Url Immagine </v-card-title>
        <v-card-text>
          <v-text-field label="Nuovo Url Immagine" v-model="image" outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary" outlined
            @click="imageDialog = false; image = '';">
            Annulla
          </v-btn>
          <v-btn
            color="primary"
            @click=" imageDialog = false; User.ImageUrl = image;">
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent :max-width="dialogWidth">
      <v-card>
        <v-card-title class="text-h5"> Vuoi davvero eliminare l' utente corrente? </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary" outlined
            @click="deleteDialog = false;">
            Annulla
          </v-btn>
          <v-btn
            color="primary"
            @click=" deleteDialog = false; deleteUser()">
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDialog" persistent :max-width="dialogWidth">
      <v-card>
        <v-card-title class="text-h5"> Selezione Utente </v-card-title>
        <v-row class="ma-0 text-center" align="center">
          <v-spacer></v-spacer>
          <v-col class="profile-box" v-for="user in Users" :key="user.Id"
            cols="12" sm="6" md="4" lg="3" xl="3">
            <v-avatar size="100">
              <v-img class="a-pointer" :src="user.ImageUrl || require('../assets/profileLogo.png')"
            @click="changeUser(user)"></v-img>
            </v-avatar>
            <h3 class="text-h5 font-weight-regular a-pointer profile-name"
            @click="changeUser(user)">{{user.Username}}</h3>
          </v-col>
          <v-col class="profile-box" cols="12" sm="6" md="4" lg="3" xl="3">
            <v-avatar size="100">
              <v-img class="a-pointer" :src="require('../assets/newProfile.png')"
            @click="createUser()"></v-img>
            </v-avatar>
            <h3 class="text-h5 font-weight-regular a-pointer profile-name"
            @click="changeUser(user)">Aggiungi</h3>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary" outlined
            @click=" userDialog = false;">
            Annulla
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AuthService from "../services/AuthService.js"
import ProfileService from "../services/ProfileService.js"
import router from "../router";

export default {
  name: "Profile",
  components: {
  },
  data: () => ({
    userId: undefined,
    show1: false,
    show2: false,
    edit: false,
    imageDialog: false,
    deleteDialog: false,
    userDialog: false,
    changePassword: false,
    password: "",
    confirmPassword: "",
    image: "",
    User: {},
    Users: [],
    error: false,
    errorMessage: "",
    AccountUsername: "",
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    editRollback() {
      this.edit = false;
      this.User = this.before;
      this.changePassword = false;  
      this.$globalContainer.User = this.before;
      this.image = "";
      this.password = "";
      this.confirmPassword = "";
      this.show1 = false;
      this.show2 = false;
    },
    updateProfile() {
      if (this.changePassword) {
        ProfileService.editPassword(this.password, this.confirmPassword).then(() => {
          this.$cookies.set("username",this.$cookies.get("username"),"30d");
          this.$cookies.set("password", this.password,"30d");
          this.$cookies.set("current_user", this.$cookies.get("current_user"), "30d");
          AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
            ProfileService.editUser(this.User.Id, this.User.Username, this.User.ImageUrl).then(data => {
              this.initializeUsers(data);
              this.edit = false;
              this.changePassword = false;
              this.password = "";
              this.confirmPassword = "";
              this.show1 = false;
              this.show2 = false;
            })
          })
        })
        .catch(error => {
          this.errorMessage = error.response.data;
          this.error = true;
          setTimeout(() => {this.error = false}, 3500);
        })
      }
      else {
        ProfileService.editUser(this.User.Id, this.User.Username, this.User.ImageUrl).then(data => {
          this.initializeUsers(data);
          this.edit = false;
        })
      }
    },
    changeUser(user) {
      this.$cookies.set("username",this.$cookies.get("username"),"30d");
      this.$cookies.set("password",this.$cookies.get("password"),"30d");
      this.$cookies.set("current_user", user.Id, "30d");
      this.$globalContainer.User = user;
      this.userDialog = false;
      this.User = this.$globalContainer.User;
    },
    createUser() {
      this.userDialog = false;
      ProfileService.addUser().then(data => {
        this.userId = data.Login.Id;
        this.AccountUsername = data.Login.Username;
        this.$globalContainer.User = data.Users.filter((el) => el.Image == null && el.Username == "Nuovo Utente")[0];
        this.$cookies.set("username",this.$cookies.get("username"),"30d");
        this.$cookies.set("password",this.$cookies.get("password"),"30d");
        this.$cookies.set("current_user", this.$globalContainer.User.Id, "30d");
        this.User = JSON.parse(JSON.stringify(this.$globalContainer.User));
        this.Users = JSON.parse(JSON.stringify(data.Users));
      })
    },
    deleteUser(){
      ProfileService.deleteUser(this.User.Id).then(data => {
        this.userId = data.Login.Id;
        this.AccountUsername = data.Login.Username;
        this.$cookies.set("username",this.$cookies.get("username"),"30d");
        this.$cookies.set("password",this.$cookies.get("password"),"30d");
        this.$cookies.set("current_user", data.Users[0].Id, "30d");
        this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
        this.User = JSON.parse(JSON.stringify(this.$globalContainer.User));
        this.Users = JSON.parse(JSON.stringify(data.Users));
      })
    },
    initializeUsers(data){
      this.userId = data.Login.Id;
      this.AccountUsername = data.Login.Username;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.User = JSON.parse(JSON.stringify(this.$globalContainer.User));
      this.Users = JSON.parse(JSON.stringify(data.Users));
    }
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.initializeUsers(data);
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.initializeUsers(data);
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
  computed: {
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "80%"
        case "sm":
          return "70%"
        case "md":
          return "75%"
        case "lg":
          return "50%"
        case "xl":
          return "35%"
        default:
          return "35%"
      }
    },
  }
};
</script>

<style>
.profile-box:hover .profile-name{
  color: #B71C1C;
  transition-duration: 250ms;
}

.profile-name{
  transition-duration: 250ms;
}
</style>
