<template>
  <v-container>
    <v-row class="pt-3 d-block d-sm-none" v-if="exit">
      <v-col class="text-center"><v-btn outlined color="primary" @click="$emit('close')">Chiudi <v-icon>mdi-close</v-icon></v-btn></v-col>
    </v-row>
    <v-row  align="center">
      <v-col cols="12" xs="12" sm="10" md="11" lg="11" xl="11">
       <h1 v-if="!film" class="ml-12 d-none d-md-block" style="text-align: left">{{title}}, ep: {{episode.Number}}</h1>
       <h1 v-if="film" class="ml-12 d-none d-md-block" style="text-align: center">{{title}}</h1>
       <h3 v-if="!film" class="ml-12 d-none d-sm-block d-md-none" style="text-align: left">{{title}}, ep: {{episode.Number}}</h3>
       <h2 v-if="film" class="ml-12 d-none d-sm-block d-md-none" style="text-align: center">{{title}}</h2>
       <h2 class="ml-12 d-block d-sm-none" style="text-align: center">{{title}}</h2>
      </v-col>
      <v-col cols="1" xs="1" sm="2" md="1" lg="1" xl="1" class="mr-0"><v-btn class="d-none d-sm-block" v-if="exit" icon color="primary" outlined @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn></v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="1" xs="1" sm="2" md="1" lg="1" xl="1" v-if="!film" class="d-flex justify-center align-center">
        <v-btn @click="$emit('click-back')" class="d-none d-sm-block" icon color="primary" x-large>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="8" md="10" lg="10" xl="10" class="d-flex justify-center align-center">
        <v-card width="95%" class="d-flex justify-center align-center" outlined color="transparent">
          <APlayer v-if="!loading && episode.Url.includes('.mp4')" :source="{ src: episode.Url || url, type: 'video/mp4' }" />
          <APlayer v-if="!loading && !episode.Url.includes('.mp4')" :source="{ src: episode.Url || url, type: 'application/x-mpegURL' }" hls />
          <v-progress-circular v-if="loading" :size="100" color="primary" indeterminate/>
        </v-card>
      </v-col>
      <v-col cols="1" xs="1" sm="2" md="1" lg="1" xl="1" v-if="!film" class="d-flex justify-center align-center">
        <v-btn @click="$emit('click-next')" class="d-none d-sm-block" icon color="primary" x-large>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" v-if="!film">
      <v-col>
        <div class="text-center d-block d-sm-none">    
          <v-btn @click="$emit('click-back')"  icon color="primary" x-large>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn :class="{'disable-events': true}" class="mx-5" outlined color="primary">
            {{episode.Number}}
          </v-btn>
          <v-btn @click="$emit('click-next')"  icon color="primary" x-large>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import APlayer from "./APlayer";

export default {
  name: "Player",
  components: {
    APlayer
  },
  props: {
    title: String,
    episode: {
      Url: String,
      Number: String,
    },
    film:{
      type:Boolean,
      default: false,
    },
    exit: {
      type:Boolean,
      default: false,
    },
    loading:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
  }),
  computed: {
    playerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerHeight / 3;
        case "sm":
          return window.innerHeight / 3;
        case "md":
          return window.innerHeight / 2;
        case "lg":
          return window.innerHeight / 1.5;
        case "xl":
          return window.innerHeight / 1.5;
        default:
          return 0;
      }
    },
  }
};
</script>

<style scoped>
.disable-events {
  pointer-events: none
}
</style>
