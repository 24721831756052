<template>
  <v-container>
      <v-container v-show="!loading">
        <Details v-model="anime" @click-watch="watchLastEpisode" @click-favourite="favourite" :text="'Ep: ' + nextEpisodeTitle"></Details>
      <Timer ref="timer" v-show="showTimer" :time="time" @timer="getTime()" />
      <Episodes v-model="ranges" @click-watch="ep => loadEpisode(ep)" @click-view-list="viewList" @click-view="view"></Episodes>
      <v-dialog v-model="dialog" width="80%" persistent>
        <v-card>
          <Player v-if="dialog" :title="anime.Title" :episode="currentEpisode" @click-back="watchPreviousEpisode" @click-next="watchNextEpisode" exit @close="closeDialog()"></Player>       
        </v-card>
      </v-dialog>
      </v-container>
      <v-container v-show="loading">
        <v-row align="center" class="ma-0 pa-0" style="height: 90vh;" >
          <v-col class="ma-0 pa-0 text-center" align="center">
            <v-progress-circular :size="100" color="primary" indeterminate/>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Details from "../../components/Details.vue"
import Episodes from "../../components/Episodes.vue"
import Timer from "../../components/Timer.vue"
import Player from "../../components/Player.vue"
import AuthService from "../../services/AuthService.js"
import GeneralService from "../../services/GeneralService.js"
import AnimeService from "../../services/AnimeService.js"
import router from "../../router";

export default {
  name: "AnimeWatch",
  components: {
    Details,
    Episodes,
    Player,
    Timer,
  },
  data: () => ({
    userId: undefined,
    anime: {Title: "", Description: "", Date: "", Duration: "", Views: "", Categories: [], Seasons: [], IsFavourite: false, IsLiked: false},
    ranges: [],
    dialog: false,
    currentEpisode: {},
    loading: true,
    time: {Days: 0, Hours: 0, Minutes: 0},
    showTimer: false,
  }),
  computed: {
    nextEpisodeTitle() {
      let stop = false;
      let epTitle = "";
      this.ranges.forEach(el => {
        if(!stop) {
          el.Episodes.forEach((ep) => {
            if(!ep.IsViewed && stop == false) {
              epTitle = ep.Number;
              stop = true;
            }
          });
        }
      });
      return epTitle;
    }
  },
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    getTime() {
      AnimeService.getDetails(this.$route.query.uri, this.$globalContainer.User.Id).then( data => {
        this.getTimeFromData(data.NextEpisode);
      })
      .catch(() => this.showTimer = false)
    },
    getTimeFromData(data) {
      if(data.Time != undefined || data.Time != null) {
        this.showTimer = true;
        let adate = data.Date.split('-');
        let atime = data.Time.split(':');
        adate = adate.map(el => parseInt(el))
        atime = atime.map(el => parseInt(el))
        let date_future = new Date(adate[0], adate[1]-1, adate[2], atime[0], atime[1], atime[2]);
        let date_now = new Date();
        let delta = Math.abs(date_future - date_now) / 1000;
        let days = Math.floor(delta / 86400);
        delta -= days * 86400;
        let hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        let minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        this.time = {Days: days, Hours: hours, Minutes: minutes}
      }
      else {
        this.showTimer = false;
      }
    },
    loadEpisode(episode){
      AnimeService.video(episode.Uid).then(data => {
        episode.Url = data.Url;
        this.currentEpisode = episode;
        this.dialog = true;
        if(!episode.IsViewed){
          episode.IsViewed = true;
          GeneralService.addView(this.$globalContainer.User.Id, this.anime.Url, this.currentEpisode.Uid, "Anime");
        }
      });
    },
    watchLastEpisode(){
      let stop = false;
      this.ranges.forEach(el => {
        if(!stop) {
          el.Episodes.forEach((ep) => {
            if(!ep.IsViewed && stop == false) {
              this.loadEpisode(ep);
              stop = true;
            }
          });
        }
      });
    },
    watchPreviousEpisode(){
      let stop = false;
      this.anime.Episodes.forEach((ep, index) => {
        if(ep.Uid == this.currentEpisode.Uid && stop == false){
          if(index != 0){
            this.loadEpisode(this.anime.Episodes[index-1]);
            stop = true;
          }
        }
      });
    },
    watchNextEpisode(){
      let stop = false;
      this.anime.Episodes.forEach((ep, index) => {
        if(ep.Uid == this.currentEpisode.Uid && stop == false){
          if(index != this.anime.Episodes.length-1){
            this.loadEpisode(this.anime.Episodes[index+1]);
            stop = true;
          }
        }
      });
    },
    favourite(){
      if(this.anime.IsFavourite) GeneralService.addFavourite(this.$globalContainer.User.Id, this.anime.Url, "Anime", this.anime.Image, this.anime.Title);
      else GeneralService.removeFavourite(this.$globalContainer.User.Id, this.anime.Url, "Anime");
    },
    view(episode){
      if(episode.IsViewed) GeneralService.addView(this.$globalContainer.User.Id, this.anime.Url, episode.Uid, "Anime");
      else GeneralService.removeView(this.$globalContainer.User.Id, this.anime.Url, episode.Uid, "Anime");
    },
    viewList(range){
      if(range.Status) {
        range.Episodes.forEach((ep => {
          if(ep.IsViewed) GeneralService.removeView(this.$globalContainer.User.Id, this.anime.Url, ep.Uid, "Anime");
        }))
      }
      else {
         range.Episodes.forEach((ep => {
          if(!ep.IsViewed) GeneralService.addView(this.$globalContainer.User.Id, this.anime.Url, ep.Uid, "Anime");
        }))
      }
    },
    setLiked(){
      this.anime.Likes ++;
      this.anime.IsLiked = true;
      this.like();
      this.likeDialog = false;
    },
    closeDialog(){
      if(!this.anime.IsLiked){
        this.likeDialog = true;
      }
      this.dialog = false;
    },
    getDetails() {
      AnimeService.getDetails(this.$route.query.uri, this.$globalContainer.User.Id).then(data => {
        this.getTimeFromData(data.NextEpisode);
        this.anime = data;
        const chunkSize = 50;
        for (let i = 0; i < this.anime.Episodes.length; i += chunkSize) {
            const chunk = this.anime.Episodes.slice(i, i + chunkSize);
            this.ranges.push({Title: `Episodi: ${chunk[0].Number}-${chunk[chunk.length-1].Number}`, Episodes: chunk})
        }
        this.loading = false;
        this.$refs.timer.getTime();
      })
    }
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.getDetails();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser()
          .then(data => {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.getDetails();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>
