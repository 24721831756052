import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
          dark: {
            primary: '#B71C1C',
            secondary: '#212121',
            accent: "#E53935",
            secondaryLight: "#BDBDBD"
          },
        },
      },
});
