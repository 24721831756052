<template>
  <v-container>
    <h1 align="center">I tuoi preferiti</h1>
    <Slider :loading="loading" :items="lastAdded" @item-click="openElement" :noInfinite="lastAdded.length < 8"></Slider>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-row class="mt-2">
          <v-col
          style="cursor: pointer;"
          v-for="item in items"
          :key="item.id"
          color="grey lighten-3"
          flat
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-card  @click="routeTo(item.url)">
            <v-img :src="item.img" height="200px">
              <v-card height="100%" color="transparent">
                <v-card color="transparent" height="40%" outlined></v-card>
                <v-card color="transparent" height="20%" outlined class="text-center">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col style="background-color: rgba(0,0,0, 0.45); border-radius: 15px;"><h1>{{ item.title }}</h1></v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card>
                <v-card color="transparent" height="40%" outlined></v-card>
              </v-card>
            </v-img>
          </v-card>
        </v-col>
        </v-row>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import AuthService from "../services/AuthService.js";
import FavouritesService from "../services/FavouritesService.js";
import Slider from "../components/Slider.vue"
import router from "../router";

export default {
  name: "Favourites",
  components: {Slider},
  data: () => ({
    userId: undefined,
    items: [
      {
        url: '/favourites/anime',
        title: "Anime",
        img: "https://c4.wallpaperflare.com/wallpaper/295/163/719/anime-anime-boys-picture-in-picture-kimetsu-no-yaiba-kamado-tanjir%C5%8D-hd-wallpaper-preview.jpg"
      },

      {
        url: '/favourites/film',
        title: "Film",
        img:
          "https://c4.wallpaperflare.com/wallpaper/779/691/639/movies-film-reel-technology-projector-8mm-wallpaper-preview.jpg"
      },
      {
        url: '/favourites/manga',
        title: "Manga",
        img:
          "https://images5.alphacoders.com/572/thumb-1920-572585.jpg"
      },

      {
        url: '/favourites/tvseries',
        title: "Serie Tv",
        img:
          "https://wallpaperaccess.com/full/346116.png"
      }
    ],
    loading: true,
    lastAdded: []
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    openElement(element){
      router.push({path:`/${element.Type.toLowerCase()}/watch`,query:{uri:element.Url.split("/").pop()}});
    },
    getDetails(){
      FavouritesService.getLastFavourites(this.$globalContainer.User.Id).then((data) => {
        this.lastAdded = data;
        this.loading = false;
      });
    }
  },
  mounted() {
    AuthService.getUser()
    .then((data) => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.getDetails();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.getDetails();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>
<style>

</style>
