<template>
  <v-container>
    <div ref="pageTop"></div>
    <v-row align="center">
      <v-img :src="chapter.Panels[selectedPanel].Url" :max-width="imageWidth" style="width: fit-content; margin: auto;">
          <v-card color="transparent" class="btn-card cursor-prev" style="display:inline-block;" outlined tile width="50%" height="100%" @click="moveUp(0)"></v-card>
          <v-card color="transparent" class="btn-card cursor-next" style="display:inline-block;" outlined tile width="50%" height="100%" @click="moveUp(1)"></v-card>
      </v-img>
    </v-row>
    <v-row align="center">
      <v-col>
        <div class="text-center">
          <v-btn :class="{ 'disable-events': true }" class="mx-5" outlined color="primary">
            {{ selectedPanel + 1 }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ReaderPages",
  props: {
    title: String,
    chapter: Object,
  },
  data: () => ({
    selectedPanel: 0,
  }),
  methods: {
    moveUp(action) {
      if(this.selectedPanel>0 && action==0){
        this.selectedPanel--
      }
      if(this.selectedPanel<this.chapter.Panels.length && action==1){
        this.selectedPanel++
      }
      this.$refs.pageTop.scrollIntoView({behavior: 'smooth', block: 'end'});
    },
  },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "60%";
        case "lg":
          return "50%";
        case "xl":
          return "50%";
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
.disable-events {
  pointer-events: none
}
.btn-card::before {
  display: none;
}
.cursor-prev {
  cursor: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/cursor-prev.cur), auto;
  cursor: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/cursor-prev.svg) 16 16, auto;
}

.cursor-next {
  cursor: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/cursor-next.cur), auto;
  cursor: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/cursor-next.svg) 16 16, auto;
}
</style>
