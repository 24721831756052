<template>
  <v-container>
    <v-row align="center">
      <v-col>
          <h1 class="text-center mt-5 mb-5">Otaku World Team</h1>
      </v-col>
    </v-row>
    <v-layout row wrap justify-center>
      <v-flex xs10 sm6 md4 lg3 xl2 v-for="dev in devs" :key=dev.name>
        <v-card flat class="text-center ma-3" min-height="305px">
          <v-responsive class="pt-5">
            <v-avatar size="100">
              <v-img :src="dev.pic"></v-img>
              </v-avatar>
          </v-responsive>
        <v-card-text>
          <div>{{dev.name}}</div>
          <div class="grey--text">{{dev.role}}</div>
        </v-card-text>
        <v-card-text>
          <p class="font-italic text-center grey--text text--lighten-4 font-weight-light text-caption" >"{{dev.quote}}"</p>
        </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src
import AuthService from "../services/AuthService.js"
import router from "../router";

export default {
  name: "About",
  components: {
  },
  data: () => ({
    devs:[
      {name:'AleAlpha',role:'Founder/FullStack Dev',pic:require('../assets/ale.png'),quote:"Se il computer è un dio, è un dio del Vecchio Testamento con un sacco di leggi e nessuna pietà."},
      {name:'FlyTxR',role:'FrontEnd Dev',pic:require('../assets/fly.jpg'),quote:'La vita è come un biscotto ma se piove si scioglie.'},
      {name:'Elshe',role:'FrontEnd Dev',pic:require('../assets/elshe.jpg'),quote:'Gli anni sono come le piramidi: contengono sempre qualche morto.'},
      {name:'TheRealArma',role:'Tester',pic:require('../assets/arma.jpg'),quote:"O megl pesc man è chill ch t faij che man toij."},
      {name:'RushBroz',role:'Tester',pic:require('../assets/will.jpeg'),quote:"Mai sottovalutare l’effetto che può avere la persona giusta nel luogo sbagliato."},
    ]
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>




