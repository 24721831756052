<template>
  <v-container>
    <h3 class="mb-2 ml-1">Il prossimo episodio uscirà tra:</h3>
    <v-row>
      <v-col cols="4" sm="2" lg="1" xl="1">
        <v-text-field readonly dense outlined label="Giorni" :value="time.Days"></v-text-field>
      </v-col>
      <v-col cols="4" sm="2" lg="1" xl="1">
        <v-text-field readonly dense outlined label="Ore" :value="time.Hours"></v-text-field>
      </v-col>
      <v-col cols="4" sm="2" lg="1" xl="1">
        <v-text-field readonly dense outlined label="Minuti" :value="time.Minutes"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Timer",
  props: {
    time: {
      Days: Number,
      Hours: Number,
      Minutes: Number,
    }
  },
  data: () => ({}),
  methods: {
    getTime() {
      this.$emit("timer");
      setTimeout(() => this.getTime(), 60000)
    }
  },
  computed: {},
};
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
</style>
