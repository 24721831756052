<template>
  <v-container>
    <h1>Film</h1>
    <!--
      <Slider :loading="loadingTopPicks" :items="topPicks" large :delay="3500" @item-click="openElement"></Slider>
      <Slider :loading="loadingSuggested" title="Selezionati per te" :items="suggested" @item-click="openElement"></Slider>   
      <Slider :loading="loadingWatchAgain" title="Guarda ancora" v-if="watchAgain.length > 0" :items="watchAgain" @item-click="openElement" :noInfinite="watchAgain.length < 8"></Slider>
      <Slider :loading="loadingMostLiked" title="I più votati" :items="mostLiked" :delay="3500" @item-click="openElement"></Slider>
      <Slider :loading="loadingFavourites" title="Dalla lista preferiti" v-if="favourites.length > 0" :items="favourites" @item-click="openElement" :noInfinite="favourites.length < 8"></Slider>
      <Slider :loading="loadingAction" title="I migliori Action" :items="action" @item-click="openElement"></Slider>
      <Slider :loading="loadingDrama" title="I migliori Drama" :items="drama" @item-click="openElement"></Slider>
      <Slider :loading="loadingFantasy" title="I migliori Fantasy" :items="fantasy" @item-click="openElement"></Slider>
      <Slider :loading="loadingComedy" title="I migliori Comedy" :items="comedy" @item-click="openElement"></Slider>
      <Slider :loading="loadingTopPicks" title="I migliori Horror" :items="horror" @item-click="openElement"></Slider>-->
  </v-container>
</template>

<script>
// @ is an alias to /src
//import Slider from "../components/Slider.vue"
//import FilmService from "../services/FilmService.js"
//import FavouritesService from "../services/FavouritesService.js"
import AuthService from "../services/AuthService.js"
import router from "../router";

export default {
  name: "Film",
  components: {
    //Slider
  },
  data: () => ({
    userId: undefined,
    topPicks: [],
    loadingTopPicks: true,
    suggested: [],
    loadingSuggested: true,
    mostLiked: [],
    loadingMostLiked: true,
    watchAgain: [],
    loadingWatchAgain: true,
    favourites: [],
    loadingFavourites: true,
    action: [],
    loadingAction: true,
    drama: [],
    loadingDrama: true,
    fantasy: [],
    loadingFantasy: true,
    comedy: [],
    loadingComedy: true,
    horror: [],
    loadingHorror: true,

  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    openElement(element){
      router.push({ path: `/film/watch`, query: { id: element.Id }});
    },
    loadCarousels(){/*
      FilmService.mostLiked(15, Math.floor(Math.random() * 101)).then((data) => {
        this.topPicks = data;
        this.loadingTopPicks = false;
      });
      FilmService.mostLiked(15,0).then((data) => {
        this.mostLiked = data;
        this.loadingMostLiked = false;
      });
      FilmService.suggested(15,0).then((data) => {
        this.suggested = data;
        this.loadingSuggested = false;
      });
      FilmService.watchAgain(30,0).then((data) => {
        this.watchAgain = data;
        this.loadingWatchAgain = false;
      });
      FavouritesService.film(30,0).then((data) => this.favourites = data).then((data) => {
        this.favourites = data;
        this.loadingFavourites = false;
      });
      FilmService.mostLiked(15,0, "Action").then((data) => {
        this.action = data;
        this.loadingAction = false;
      });
      FilmService.mostLiked(15,0, "Drama").then((data) => {
        this.drama = data;
        this.loadingDrama = false;
      });
      FilmService.mostLiked(15,0, "Fantasy").then((data) => {
        this.fantasy = data;
        this.loadingFantasy = false;
      });
      FilmService.mostLiked(15,0, "Comedy").then((data) => {
        this.comedy = data;
        this.loadingComedy = false;
      });
      FilmService.mostLiked(15,0, "Horror").then((data) => {
        this.horror = data;
        this.loadingHorror = false;
      });*/
    }
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.loadCarousels();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.loadCarousels();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>
