<template>
  <v-container>
    <v-row align="center" class="my-2 py-0" v-for="(panel, index) in chapter.Panels" :key="index">
      <v-col class="my-0 py-0">
        <v-img :src="panel.Url" :max-width="imageWidth" 
        style="width: fit-content; margin: auto;">
        <v-card height="100%" color="transparent" outlined>
          <v-card height="90%" color="transparent" outlined>
          </v-card>
          <v-card height="10%" class="text-right" color="transparent" outlined>
            <v-btn fab style="background-color: rgba(0,0,0,0.5);" :class="{ 'disable-events': true }" 
            class="mx-5 mb-1" color="rgba(0,0,0,0.5)">
              {{ index + 1 }}
            </v-btn>
          </v-card>
        </v-card>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ReaderList",
  props: {
    title: String,
    chapter: Object,
  },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "60%";
        case "lg":
          return "50%";
        case "xl":
          return "50%";
        default:
          return 0;
      }
    },
  }
};
</script>

<style scoped>
.disable-events {
  pointer-events: none
}
</style>
