<template>
  <v-card color="transparent" outlined>
    <v-row class="text-center pa-0 ma-0">
        <v-col><h1>Anime Preferiti</h1></v-col>
      </v-row>
      <div>
        <v-row class="pt-0 mb-0" >
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-row class="pt-0 mb-0" align="center">
              <v-col
                v-for="(element, index) in results"
                v-bind:item="element"
                v-bind:index="index"
                v-bind:key="index"
                align-self="center"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    @click="openElement(element)"
                    class="mx-auto a-cursor"
                    width="250"
                    min-width="250"
                    :elevation="hover ? 24 : 2"
                  >
                    <v-img :src="element.Image" height="350" width="250"> 
                      <v-card tile height="20%" v-if="hover" color="transparent" outlined >
                      </v-card>
                      <v-card tile height="64%" v-if="hover" color="transparent" outlined >
                        <v-row align="center" class="fill-height text-center">
                          <v-col>
                            <v-btn icon x-large color="primary" style="background-color: rgba(0,0,0,0.6); ">
                              <v-icon>mdi-play</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card tile height="16%" v-if="hover" style="background-color: rgba(0,0,0,0.6);">
                        <div class="text-center">
                          <h3>{{element.Title}}</h3>
                        </div>
                      </v-card>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
  </v-card>
</template>

<script>
// @ is an alias to /src
import FavouritesService from "../../services/FavouritesService.js"
import AuthService from "../../services/AuthService.js"
import router from "../../router";

export default {
  name: "FavouriteAnime",
  components: {
  },
  data: () => ({
    userId: undefined,
    results: [],
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    validateTitle(title) {
      if (title.length > 53) {
        title = title.substring(0, 50);
        title += "...";
      } else {
        title = title.padEnd(32, "⠀");
      }
      return title;
    },
    openElement(element){
      router.push({ path: `/anime/watch`, query:{uri:element.Url.split("/").pop()}});
    },
    getFavourites(){
      FavouritesService.getFavourites(this.$globalContainer.User.Id, "Anime").then(data => {
        this.results = data;
      });
    }
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.getFavourites();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser()
          .then(data => {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.getFavourites();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>
