import Vue from 'vue'
import VueRouter from 'vue-router'
import Film from '../views/Film.vue'
import TvSeries from '../views/TvSeries.vue'
import Anime from '../views/Anime.vue'
import Manga from '../views/Manga.vue'
import Favourites from '../views/Favourites.vue'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import FavouriteAnime from '../views/favourites/Anime.vue'
import FavouriteManga from '../views/favourites/Manga.vue'
import FavouriteFilm from '../views/favourites/Film.vue'
import FavouriteTvSeries from '../views/favourites/TvSeries.vue'
import AnimeWatch from '../views/watch/Anime.vue'
import MangaWatch from '../views/watch/Manga.vue'
import FilmWatch from '../views/watch/Film.vue'
import TvSeriesWatch from '../views/watch/TvSeries.vue'
import Profile from '../views/Profile.vue'
import Search from '../views/Search.vue'
import ErrorPage from '../views/ErrorPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/film',
    name: 'Film',
    component: Film
  },
  {
    path: '/tvseries',
    name: 'TvSeries',
    component: TvSeries
  },
  {
    path: '/anime',
    name: 'Anime',
    component: Anime
  },
  {
    path: '/manga',
    name: 'Manga',
    component: Manga
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: Favourites
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/favourites/anime',
    name: 'FavouriteAnime',
    component: FavouriteAnime
  },
  {
    path: '/favourites/manga',
    name: 'FavouriteManga',
    component: FavouriteManga
  },
  {
    path: '/favourites/film',
    name: 'FavouriteFilm',
    component: FavouriteFilm
  },
  {
    path: '/favourites/tvseries',
    name: 'FavouriteTvSeries',
    component: FavouriteTvSeries
  },
  {
    path: '/anime/watch',
    name: 'AnimeWatch',
    component: AnimeWatch
  },
  {
    path: '/manga/watch',
    name: 'MangaWatch',
    component: MangaWatch
  },
  {
    path: '/film/watch',
    name: 'FilmWatch',
    component: FilmWatch
  },
  {
    path: '/tvseries/watch',
    name: 'TvSeriesWatch',
    component: TvSeriesWatch
  },
  {
    path: "/profile",
    name: 'Profile',
    component: Profile
  },
  {
    path: "/search",
    name: 'Search',
    component: Search
  },
  {
    path: "/*",
    name: 'Error',
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
