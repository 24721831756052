<template>
  <v-container>
    <v-row align="center" :class="loading ? 'text-center' : ''">
      <v-col cols="1"><v-btn v-show="loading==false" @click="goToPrevSlide()" icon color="primary"  x-large class="hidden-sm-and-down display-md-and-up"> <v-icon>mdi-chevron-left</v-icon></v-btn></v-col>
      <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
        <h2 class="my-0 text-center">{{title}}</h2>
        <hooper class="mt-1" ref="hooper" :infinite-scroll="!noInfinite" :itemsToShow="displayElements"
         :centerMode="!noInfinite" :playSpeed="delay" :autoPlay="delay>0" hoverPause :wheelControl="false"
          @beforeSlide="isSliding = true" @afterSlide="isSliding = false">
          <slide v-for="(item, index) in [1,2,3,4,5,6,7,8,9,10]" v-bind:key="index" :index="index" v-show="loading==true">
            <v-skeleton-loader 
            class="mx-auto"
            :max-height="height"
            :max-width="determineNewWidth(375, 270, height)"
            type="card"
          ></v-skeleton-loader>
          </slide>
          <slide v-for="(item, index) in items" v-bind:key="index" :index="index">
            <v-hover v-slot="{ hover }">
              <v-card :height="height" :width="determineNewWidth(375, 270, height)">
                <v-img :height="height" :src="item.Image" :style="`cursor: ${hover ? 'pointer' : 'default'}`" @click="itemClick(item)">
                  <v-card tile height="20%" v-if="hover" color="transparent" outlined >
                    <v-row align="center">
                      <v-col>
                        <v-chip class="mx-1 my-1 pt-1" color="secondary" small >
                          <v-icon class="i-yellow mb-1" color="alert">mdi-star</v-icon>
                          {{ item.Valutation }}
                        </v-chip>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <v-chip color="secondary" small v-if="item.Type != undefined">
                          <v-icon v-if="item.Type.toLowerCase() == 'film'" color="alert">mdi-movie</v-icon>
                          <v-icon v-if="item.Type.toLowerCase() == 'tvseries'" color="alert">mdi-television-box</v-icon>
                          <v-icon v-if="item.Type.toLowerCase() == 'anime'" color="alert">mdi-movie-filter</v-icon>
                          <v-icon v-if="item.Type.toLowerCase() == 'manga'" color="alert">mdi-book-open-blank-variant</v-icon>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card tile height="60%" v-if="hover" color="transparent" outlined >
                    <v-row align="center" class="fill-height text-center">
                      <v-col>
                        <v-btn icon x-large color="primary" style="background-color: rgba(0,0,0,0.6); ">
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card tile height="20%" v-if="hover" style="background-color: rgba(0,0,0,0.6);">
                    <div class="text-center">
                      <h3>{{item.Title}}</h3>
                    </div>
                  </v-card>
                </v-img>
              </v-card>
            </v-hover>
          </slide>
        </hooper>
      </v-col>
      <v-col cols="1"><v-btn v-if="loading==false" @click="goToNextSlide()" icon color="primary"  x-large class="hidden-sm-and-down display-md-and-up"> <v-icon>mdi-chevron-right</v-icon></v-btn></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Hooper, Slide } from 'hooper';
  import 'hooper/dist/hooper.css';

export default {
  name: "Slider",
  components: {
      Hooper,
      Slide,
  },
  props: {
    title: String,
    noInfinite: {
      type: Boolean,
      default: false
    },
    items: Array,
    delay: {
      type: Number,
      default: 0
    },
    large:{
      type:Boolean,
      default: false,
    },
    loading:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    display: 5,
    isSliding: false,
  }),
  methods: {
    determineNewWidth(originalHeight, originalWidth, newHeight) {
      return (originalWidth / originalHeight) * newHeight;
    },
    goToNextSlide(){
      this.$refs.hooper.slideNext();
    },
    goToPrevSlide(){
      this.$refs.hooper.slidePrev();
    },
    itemClick(item) {
      if(!this.isSliding){
        this.$emit("item-click", item);
      }
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.large ? window.innerHeight / 4  : window.innerHeight / 4.5;
        case "sm":
          return this.large ? window.innerHeight / 4  : window.innerHeight / 4.5;
        case "md":
          return this.large ? window.innerHeight / 3.5 :  window.innerHeight / 4;
        case "lg":
          return this.large ? window.innerHeight / 3 :  window.innerHeight / 3.5;
        default:
          return this.large ? window.innerHeight / 2.5 :  window.innerHeight / 3;
      }
    },
    displayElements(){
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
            return this.large ? 2.1 : 2.3;
        case "sm":
            return this.large ? 3.7 : 4.2;
        case "md":
            return this.large ? 3.5 : 4;
        case "lg":
            return this.large ? 4.2 : 5;
        case "xl":
            return this.large ? 5.2 : 6;
        default:
            return  this.display;
      }
    },
  },
};
</script>

<style>
i.v-icon.v-icon.i-yellow {
  color: rgb(196, 196, 3);
}
.hooper{
  height: fit-content;
}
.slide{
  width: fit-content;
}
</style>
