<template>
  <v-container>
    <v-row>
        <h1 class="text-h4 my-16" style="width: fit-content; margin: auto;">Errore 404</h1>
    </v-row>
    <v-row class="my-16">
        <v-col cols="12"><h1 class="text-h3" style="width: fit-content; margin: auto; color:#b71c1c;">Ooops qualcosa è andato storto!</h1></v-col>
        <v-col cols="12"><h1 class="text-h5" style="width: fit-content; margin: auto;">La pagina che stai cercando non è disponibile</h1></v-col>
    </v-row>
    <v-row class="mt-16">
        <v-spacer></v-spacer>
        <v-btn class="mt-16" color="primary" @click="backHome()">Torna alla home</v-btn>
        <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "../services/AuthService.js"
import router from "../router";

export default {
  name: "Error",
  data: () => ({
    userId: undefined,
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    backHome(){
        this.routeTo(this.$globalContainer.MainPage);
    }
  },
  
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>
