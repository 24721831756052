import axios from 'axios';

class GeneralService {

    // EPISODE => ANIME: Episode.Uid, MANGA: Chapter.Url, Film: Film.Url, TvSeries: Episode.Uid 

  static addView(id, url, episode, type) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/general/views?url=${url}&episode=${episode}&type=${type}&id=${id}`, {}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static removeView(id, url, episode, type) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/general/views?url=${url}&episode=${episode}&type=${type}&id=${id}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static addFavourite(id, url, type, image, title) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/general/favourites?url=${url}&type=${type}&image=${image}&title=${title}&id=${id}`, {}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static removeFavourite(id, url, type) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.delete(`${process.env.VUE_APP_BASE_URL}/api/general/favourites?url=${url}&type=${type}&id=${id}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default GeneralService;