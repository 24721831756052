<template>
  <v-container>
    <v-row class="text-center">
      <v-spacer></v-spacer>
      <v-col class="my-10" cols="10" xs="12" sm="6" md="4" lg="3" xl="3">
        <h2>Login</h2>
        <v-alert type="error" outlined v-model='error' transition="scale-transition" text> {{errorMessage}}</v-alert>
        <v-text-field
          v-model="username"
          outlined
          label="Username"
        ></v-text-field>
        <v-text-field
          v-model="password"
          outlined
          type="password"
          label="Password"
        ></v-text-field>
        <v-btn class="mb-2" color="primary" @click="login()">Login</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "../services/AuthService.js"
import router from "../router";
//import axios from "axios";

export default {
  name: "Login",

  data: () => ({
    username: "",
    password: "",
    error: false,
    errorMessage: '',
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    login() {
      AuthService.login(this.username, this.password)
      .then((data) => {
        this.$cookies.set("username",this.username,"30d");
        this.$cookies.set("password",this.password,"30d");
        this.$cookies.set("current_user", data[0].Id,"30d");
        this.routeTo(this.$globalContainer.MainPage);
      })
      .catch(error => {
        this.errorMessage = error.response.data;
        this.error = true;
        setTimeout(() => {this.error = false}, 3500);
      })
    },
  },
  mounted() {
    AuthService.getUser()
    .then(() => this.routeTo(this.$globalContainer.MainPage))
    .catch(() => {
        this.$globalContainer.User = {};
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          this.routeTo(this.$globalContainer.MainPage)
        })
      }
    });
  },
};
</script>
