import axios from 'axios';

class FavouritesService {

  static getFavourites(id, type) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/general/favourites?id=${id}&type=${type}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static getLastFavourites(id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/general/favourites?id=${id}&sort=${'Date'}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

}

export default FavouritesService;