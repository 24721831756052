<template>
  <v-container>
      <v-container v-show="!loading">
        <Details v-model="film" @click-watch="watchFilm" @click-favourite="favourite" @click-view="view"></Details>
        <v-dialog v-model="dialog" width="80%" persistent>
          <v-card>
            <Player v-if="dialog && filmUri != 'none'" :title="film.Title" :episode="{Number: '1', Url: filmUri}" film exit @close="closeDialog()"></Player>   
          </v-card>
        </v-dialog>
      </v-container>
      <v-container v-show="loading">
        <v-row align="center" class="ma-0 pa-0" style="height: 90vh;" >
          <v-col class="ma-0 pa-0 text-center" align="center">
            <v-progress-circular :size="100" color="primary" indeterminate/>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Details from "../../components/Details.vue"
import Player from "../../components/Player.vue"
import AuthService from "../../services/AuthService.js"
import FilmService from "../../services/FilmService.js"
import GeneralService from "../../services/GeneralService.js"
import router from "../../router";

export default {
  name: "FilmWatch",
  components: {
    Details,
    Player
  },
  data: () => ({
    userId: undefined,
    film: {Title: "", Description: "", Date: "", Views: "", Categories: [], IsFavourite: false, IsViewed: false},
    dialog: false,
    loading: true,
    filmUri: 'none'
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    watchFilm(){
      this.dialog = true;
      FilmService.getVideo(this.film.Uri).then(data => {
        this.filmUri = data;
      })
      if(!this.film.IsViewed){
        this.film.IsViewed = true;
        GeneralService.addView(this.$globalContainer.User.Id, this.film.Url, this.film.Url, "Film");
      }
    },
    favourite(){
      if(this.film.IsFavourite) GeneralService.addFavourite(this.$globalContainer.User.Id, this.film.Url, "Film", this.film.Image, this.film.Title);
      else GeneralService.removeFavourite(this.$globalContainer.User.Id, this.film.Url, "Film");
    },
    view(){
      if(this.film.IsViewed)GeneralService.addView(this.$globalContainer.User.Id, this.film.Url, this.film.Url, "Film");
      else GeneralService.removeView(this.$globalContainer.User.Id, this.film.Url, this.film.Url, "Film");
    },
    closeDialog(){
      if(!this.film.IsLiked){
        this.likeDialog = true;
      }
      this.dialog = false;
    },
    getDetails(){
      FilmService.getDetails(this.$route.query.uri, this.$globalContainer.User.Id).then(data => {
        this.film = data;
        this.loading = false;
      })
    }
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.getDetails();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser()
          .then(data => {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.getDetails();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>