<template>
  <div class="player" ref="container">
    <div class="player__sizer">
      <video
        ref="video"
        @play="onVideoPlay"
        @pause="onVideoPause"
        @click="toggleBar()"
        @volumechange="onVideoVolumeChange"
        @loadedmetadata="onVideoLoadedMetaData"
        @timeupdate="onVideoTimeUpdate"
        @progress="onVideoProgress"
        @loadstart="isLoading = true"
        @loadeddata="isLoading = false"
        @waiting="isWaiting = true"
        @canplay="isWaiting = false"
        @mousemove="mousemove"
        autoplay
      >
        <source :type="source.type" :src="source.src" />
      </video>
    </div>

    <div :class="!showBar ?'' :'player__gradient'" />

    <div class="player__loader" v-show="isLoading || isWaiting">
      <v-progress-circular :size="128" color="white" indeterminate />
    </div>

    <div class="player__loader" v-show="showSkip">
      <h1 style="margin-left: +25px; margin-top: +115px; color: rgba(183, 28, 28, .75);">
        {{skipMsg}}
      </h1>
    </div>

    <div class="player__loader" v-show="showBar">
      <v-btn @click="skipSeconds()" 
      class="mt-7" fab style="background-color: rgba(1,1,1, .5); margin-left: +115px;">
        <v-icon>mdi-skip-forward-outline</v-icon>
      </v-btn>
    </div>

    <div class="player__loader" v-show="showBar">
      <v-btn @click="backSeconds()"
       class="mt-7" fab style="background-color: rgba(1,1,1, .5); margin-left: -45px;">
        <v-icon>mdi-skip-backward-outline</v-icon>
      </v-btn>
    </div>

    <div class="player__loader ml-8 mt-6" v-show="!isPlaying || showBar">
      <v-btn fab large style="background-color: rgba(1,1,1, .5);" @click="togglePlayVideo()">
        <v-icon v-show="!isPlaying">mdi-play</v-icon>
        <v-icon v-show="isPlaying">mdi-pause</v-icon>
      </v-btn>
    </div>

    <div class="player__controls" v-show="showBar">

      <v-slider
        :style="`max-height: ${barH}px;`"
        dense
        :max="duration"
        :value="currentTime"
        @change="seek"
        min="0"
        track-fill-color="primary"
        thumb-color="white"
        class="ma-0 pa-0"
      ></v-slider>

      <v-toolbar dense color="transparent" flat class="player__toolbar">
        <v-btn color="white" icon @click="togglePlayVideo()" >
          <v-icon>
            {{ isPlaying ? "mdi-pause" : "mdi-play" }}
          </v-icon>
        </v-btn>

        <v-btn color="white" icon @click="toggleSound">
          <v-icon>
            {{ isMuted ? "mdi-volume-off" : "mdi-volume-high" }}
          </v-icon>
        </v-btn>

        <div class="small-slider mx-5 mt-5" :style="{ width: '120px' }">
          <v-slider
            class="ma-0 pa-0"
            :max="1"
            :value="isMuted ? 0 : volume"
            @input="onVolumeSliderChange"
            min="0"
            track-fill-color="primary"
            thumb-color="white"
            step="0"
          ></v-slider>
        </div>

        <div class="player__time" :style="`font-size: ${fontSize}px`">
          {{ currentTimeFormatted }} / {{ durationFormatted }}
        </div>

        <v-spacer />

        <v-btn color="white" icon @click="toggleFullscreen">
          <v-icon>
            {{ isFullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
          </v-icon>
        </v-btn>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
import "./APlayer.scss";
import Hls from 'hls.js';

var TimeFormat = require("hh-mm-ss");
const screenfull = require("screenfull");

export default {
  name: "APlayer",

  props: {
    source: {
      type: Object,
      required: true,
      validator: source => source.type && source.src
    },
    hls: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isPlaying: false,
    isMuted: false,
    isFullscreen: false,
    showBar: true,
    isLoading: false,
    isWaiting: false,
    volume: 1,
    duration: 0,
    currentTime: 0,
    buffered: 0,
    showSkip: false,
    skipMsg: "",
    lastMove: new Date()
  }),

  computed: {
    currentTimeFormatted() {
      return TimeFormat.fromS(Math.round(this.currentTime), "hh:mm:ss");
    },

    durationFormatted() {
      return TimeFormat.fromS(Math.round(this.duration), "hh:mm:ss");
    },

    bufferedStyle() {
      return {
        transformOrigin: "left center",
        transform: `scaleX(${this.buffered})`
      };
    },

    fontSize(){
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 12;
        case "md":
          return 15;
        case "lg":
          return 20;
        case "xl":
          return 20;
        default:
          return 20;
      }
    },

    barH(){
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 20;
        case "sm":
          return 20;
        default:
          return 30;
      }
    }
  },

  watch: {
    source() {
      this.isPlaying = false;
      this.isWaiting = false;
      this.buffered = 0;
      this.currentTime = 0;

      this.$nextTick(() => {
        this.$refs.video.load();
      });
    }
  },

  mounted() {
    document.addEventListener("keyup", this.onDocumentKeyUp);
    this.hideBar();
    screenfull.onchange(this.onFullscreenChange);
    if(this.hls) {
      let hls = new Hls();
      let stream = this.source.src;
      let video = this.$refs["video"];
      hls.loadSource(stream);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    }
  },

  methods: {
    skipSeconds() {
      const { video } = this.$refs;
      video.currentTime += 15;
      this.currentTime += 15;
      this.showSkip = true;
      this.skipMsg = "+15s"
      setTimeout(() => this.showSkip = false , 2000)
    },

    backSeconds() {
      const { video } = this.$refs;
      video.currentTime -= 15;
      this.currentTime -= 15;
      this.showSkip = true;
      this.skipMsg = "-15s"
      setTimeout(() => this.showSkip = false , 2000)
    },

    onDocumentKeyUp(e) {
      if (e.keyCode === 32) {
        this.togglePlayVideo();
      }
      if (e.keyCode === 39) {
        this.skipSeconds();
      }
      if (e.keyCode === 37) {
        this.backSeconds();
      }
    },

    onVideoPlay() {
      this.isPlaying = true;
    },

    onVideoPause() {
      this.isPlaying = false;
    },

    onVideoLoadedMetaData() {
      const { video } = this.$refs;
      this.duration = video.duration;
      this.currentTime = video.currentTime;
    },

    onVideoTimeUpdate() {
      const { video } = this.$refs;
      this.currentTime = video.currentTime;
    },

    onVideoVolumeChange() {
      const { video } = this.$refs;
      this.isMuted = video.muted;
      this.volume = video.volume;
    },

    onVolumeSliderChange(value) {
      const { video } = this.$refs;

      if (video.muted && value > 0) {
        video.muted = false;
      }

      if (value <= 0) {
        video.muted = true;
      }

      video.volume = value;
    },

    onFullscreenChange() {
      this.isFullscreen = this.$el === screenfull.element;
    },

    onVideoProgress() {
      const { video } = this.$refs;
      const { duration } = video;

      if (duration > 0) {
        const { currentTime, buffered } = video;
        const bufferedLength = buffered.length;

        for (let i = 0; i < bufferedLength; i++) {
          const start = buffered.start(i);
          const end = buffered.end(i);

          if (start < currentTime && end > currentTime) {
            this.buffered = end / duration;
          }
        }
      }
    },

    toggleBar() {
      const { container } = this.$refs;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (!this.showBar) {
        this.showBar = true;
        this.lastMove = new Date();
        container.style.cursor = "default";
        } else {
          this.showBar = false;
          container.style.cursor = "none";
        }
      }
      else {
        this.togglePlayVideo();
      }
    },

    togglePlayVideo() {
      const { video } = this.$refs;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },

    hideBar() {
      let { container } = this.$refs;
      let barHide = this.hideBar;
      if (new Date().getTime() - this.lastMove.getTime() >= 3500) {
        this.showBar = false;
        container.style.cursor = "none";
        setTimeout(() => barHide(), 1000);
      } else {
        setTimeout(() => barHide(), 1000);
      }
    },

    mousemove() {
      let { container } = this.$refs;
      container.style.cursor = "default";
      if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        this.showBar = true;
      }
      this.lastMove = new Date();
    },

    toggleSound() {
      const { video } = this.$refs;

      if (video.volume <= 0) {
        video.volume = 0.5;
      }

      video.muted = !video.muted;
    },

    toggleFullscreen() {
      if (!screenfull.isEnabled) return;

      const { $el } = this;

      if (screenfull.element === $el) {
        screenfull.exit();
      } else {
        screenfull.request($el);
      }
    },

    seek(time) {
      const { video } = this.$refs;
      this.currentTime = time;
      video.currentTime = time;
    }
  }
};
</script>

<style>
.v-slider__thumb {
  height: 15px;
  width: 15px;
}
.v-slider--horizontal .v-slider__track-container {
    height: 8px;
  }
</style>
