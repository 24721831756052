<template>
  <v-container>
    <h1>Manga</h1>
    <Slider :loading="loadingTopPicks" :items="topPicks" large :delay="3500" @item-click="openElement"></Slider>
    <Slider :loading="loadingNewPicks" :items="newPicks" title="Le Nuove Uscite" @item-click="openElement"></Slider>
    <Slider :loading="loadingAzione" title="I migliori Action" :items="azione" @item-click="openElement"></Slider>
    <Slider :loading="loadingDrammatico" title="I migliori Drammatici" :items="drammatico" @item-click="openElement"></Slider>
    <Slider :loading="loadingFantasy" title="I migliori Fantasy" :items="fantasy" @item-click="openElement"></Slider>
    <Slider :loading="loadingRomantico" title="I migliori Romantici" :items="romantico" @item-click="openElement"></Slider>
    <Slider :loading="loadingHorror" title="I migliori Horror" :items="horror" @item-click="openElement"></Slider>
    <Slider :loading="loadingShounen" title="I migliori Shounen" :items="shounen" @item-click="openElement"></Slider>

      <!--<Slider :loading="loadingTopPicks" :items="topPicks" large :delay="3500" @item-click="openElement"></Slider>
      <Slider :loading="loadingSuggested" title="Selezionati per te" :items="suggested" @item-click="openElement"></Slider>   
      <Slider :loading="loadingKeepWatching" title="Continua a guardare" v-if="keepWatching.length > 0" :items="keepWatching" @item-click="openElement" :noInfinite="keepWatching.length < 8"></Slider>
      <Slider :loading="loadingMostLiked" title="I più votati" :items="mostLiked" :delay="3500" @item-click="openElement"></Slider>
      <Slider :loading="loadingFavourites" title="Dalla lista preferiti" v-if="favourites.length > 0" :items="favourites" @item-click="openElement" :noInfinite="favourites.length < 8"></Slider>
      <Slider :loading="loadingAzione" title="I migliori Action" :items="azione" @item-click="openElement"></Slider>
      <Slider :loading="loadingDrammatico" title="I migliori Drammatici" :items="drammatico" @item-click="openElement"></Slider>
      <Slider :loading="loadingFantasy" title="I migliori Fantasy" :items="fantasy" @item-click="openElement"></Slider>
      <Slider :loading="loadingRomantico" title="I migliori Romantici" :items="romantico" @item-click="openElement"></Slider>
      <Slider :loading="loadingHorror" title="I migliori Horror" :items="horror" @item-click="openElement"></Slider>-->
      
  </v-container>
</template>

<script>
// @ is an alias to /src
import Slider from "../components/Slider.vue"
import MangaService from "../services/MangaService.js"
//import FavouritesService from "../services/FavouritesService.js"
import AuthService from "../services/AuthService.js"
import router from "../router";

export default {
  name: "Manga",
  components: {
    Slider
  },
  data: () => ({
    userId: undefined,
    topPicks: [],
    newPicks: [],
    suggested: [],
    mostLiked: [],
    keepWatching: [],
    favourites: [],
    azione: [],
    drammatico: [],
    fantasy: [],
    romantico: [],
    horror: [],
    shounen: [],
    loadingTopPicks: true,
    loadingNewPicks: true,
    loadingSuggested: true,
    loadingKeepWatching: true,
    loadingMostLiked: true,
    loadingFavourites: true,
    loadingAzione: true,
    loadingDrammatico: true,
    loadingFantasy: true,
    loadingRomantico: true,
    loadingHorror: true,
    loadingShounen: true,
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    openElement(element){
      router.push({ path: `/manga/watch`, query: { id: element.Id }});
    },
    loadCarousels(){
      console.log("prova")
      MangaService.top().then((data) => {
        this.topPicks = data.Results;
        this.loadingTopPicks = false;
      });
      MangaService.new().then((data) => {
        this.newPicks = data.Results;
        this.loadingNewPicks = false;
      });
      MangaService.category("azione").then((data) => {
        this.azione = data.Results;
        this.loadingAzione = false;
      });
      MangaService.category("drammatico").then((data) => {
        this.drammatico = data.Results;
        this.loadingDrammatico = false;
      });
      MangaService.category("fantasy").then((data) => {
        this.fantasy = data.Results;
        this.loadingFantasy = false;
      });
      MangaService.category("romantico").then((data) => {
        this.romantico = data.Results;
        this.loadingRomantico = false;
      });
      MangaService.category("horror").then((data) => {
        this.horror = data.Results;
        this.loadingHorror = false;
      });
      MangaService.category("shounen").then((data) => {
        this.shounen = data.Results;
        this.loadingShounen = false;
      });
    }
  },
  
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.loadCarousels();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.loadCarousels();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
};
</script>
