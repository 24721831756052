<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      color="secondary"
      temporary
      dark
    >
      <v-list-item class="">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-row align="center">
              <v-col class="mr-0 pr-0 a-pointer" @click="routeTo(getMainPage)" cols="2"><v-img width="90%" src="./assets/Logo.png"></v-img></v-col>
              <v-col class="ml-0 pl-0"> <b @click="routeTo(getMainPage)" class="a-pointer">Otaku World</b> </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link @click="routeTo(item.link)" :disabled="!item.enabled">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block outlined color="primary" @click="logout()">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="secondary" dark >
      <v-app-bar-nav-icon v-if="!(Object.keys(getUser).length === 0)"
      @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="a-cursor mx-1 d-none d-sm-block">
        <v-row align="center">
          <v-col class="mr-0 pr-0 a-pointer" @click="routeTo(getMainPage)" cols="2"><v-img width="90%" src="./assets/Logo.png"></v-img></v-col>
          <v-col class="ml-0 pl-0"> <b @click="routeTo(getMainPage)" class="a-pointer">Otaku World</b> </v-col>
        </v-row>
      </v-toolbar-title>
      <v-row align="center">
        <v-spacer></v-spacer>
        <v-col :class="`mr-${searchBarPadding}`" cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
          <v-text-field 
           append-icon="mdi-magnify"
           v-model="$globalContainer.Search"
           @click:append="/*NADA*/"
            v-if="!(Object.keys(getUser).length === 0)"
           @focus="/*NADA*/" label="Cerca..." class="mt-5"></v-text-field>
        </v-col>
        <v-col class="d-none d-lg-block" 
        cols="0" xs="0" sm="0" md="0" lg="2" xl="4">
        </v-col>
        <v-col class="d-none d-lg-block" 
        cols="0" xs="0" sm="0" md="0" lg="3" xl="2">
          <v-row>
            <v-avatar>
              <v-img
                :src="Object.keys(getUser).length === 0 ? '' : getUser.ImageUrl || require('./assets/profileLogo.png')"
                aspect-ratio="1.0"
                @click="routeTo('/profile')" class="a-pointer"
              >
              </v-img>
            </v-avatar>
            <h2 class="ml-2 mt-1 a-pointer" @click="routeTo('/profile')"
            >{{getUser.Username}}</h2>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AuthService from "./services/AuthService.js"
import router from "./router"

export default {
  name: 'App',
  data: () => ({
    items: [
      { title: "Anime", icon: "mdi-movie-filter", link: "/anime", enabled: true},
      { title: "Manga", icon: "mdi-book-open-blank-variant", link: "/manga", enabled: true},
      { title: "Preferiti", icon: "mdi-star", link: "/favourites", enabled: true},
      { title: "Crediti", icon: "mdi-information-outline", link: "/about", enabled: true },
      { title: "Profilo", icon: "mdi-account", link: "/profile", enabled: true },
    ],
    drawer: false,
  }),
  created() {
    document.title = "Otaku World";
  },
  methods: {
    routeTo(link) {
      this.$globalContainer.Search = undefined;
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    logout() {
      AuthService.logout().finally( () => {
        this.$cookies.remove('username');
        this.$cookies.remove('password');
        this.$cookies.remove('current_user');
        this.routeTo("/");
      });
    },
  },
  computed: {
    getUser(){
      return this.$globalContainer.User;
    },
    getMainPage(){
      return this.$globalContainer.MainPage;
    },
    searchBarPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return "16"
        default:
          return "0"
      }
    },
  },
  watch: {
    '$globalContainer.Search'(newval){
      if(newval != undefined) {
        this.$router.push({path:'/search',query:{text:newval}});
      }
    },
  }
};
</script>

<style>
.a-pointer {
  cursor: pointer;
}
i.v-icon.v-icon.i-yellow {
  color: rgb(196, 196, 3);
}
</style>