<template>
  <v-card tile color="transparent" outlined class="ma-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" xs="12" sm="10" md="10" lg="8" xl="6">
        <v-tabs v-model="type" fixed-tabs background-color="transparent">
          <v-tab @click="selType = 0;">Anime</v-tab>
          <v-tab @click="selType = 1;">Manga</v-tab>
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div ref="pageTop"></div>
    <v-row class="pt-0 mb-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-row class="pt-0 mb-0">
          <v-col
            v-for="(element, index) in results"
            v-bind:item="element"
            v-bind:index="index"
            v-bind:key="index"
            align-self="center"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                @click="openElement(element)"
                class="mx-auto a-cursor"
                width="250"
                min-width="250"
                :elevation="hover ? 24 : 2"
              >
                <v-img :src="element.Image" height="350" width="250"> 
                  <v-card tile height="20%" v-if="hover" color="transparent" outlined >
                  </v-card>
                  <v-card tile height="64%" v-if="hover" color="transparent" outlined >
                    <v-row align="center" class="fill-height text-center">
                      <v-col>
                        <v-btn icon x-large color="primary" style="background-color: rgba(0,0,0,0.6); ">
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card tile height="16%" v-if="hover" style="background-color: rgba(0,0,0,0.6);">
                    <div class="text-center">
                      <h3>{{element.Title}}</h3>
                    </div>
                  </v-card>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="pages != 0" class="ma-0">
      <v-col>
        <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// @ is an alias to /src
import AuthService from "../services/AuthService.js";
import AnimeService from "../services/AnimeService.js";
import MangaService from "../services/MangaService.js";
import router from "../router";

export default {
  name: "Search",
  components: {},
  data: () => ({
    type: -1,
    selType: -1,
    results: "",
    pages: 0,
    page: 0
  }),
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    openElement(element){
      if(this.type == 0){
        router.push({path:`/anime/watch`,query:{uri:element.Url.split("/").pop()}});
      }
      else if(this.type == 1){
        let splitted = element.Url.split("/");
        router.push({path:`/manga/watch`,query:{uri:splitted[splitted.length-2]}});
      }
    },
    getSearchItems() {
      switch (parseInt(this.type)) {
        case 0:
          this.$route.query.page = this.$route.query.page == undefined ? 1 : this.$route.query.page;
          this.page = parseInt( this.$route.query.page);
          AnimeService.search(this.$route.query.text || "", this.$route.query.page || 1).then(data => {
            this.results = data.Results;
            this.pages = parseInt(data.Pages);
          })
          break;
        case 1:
          this.$route.query.page = this.$route.query.page == 0 ? 1 : this.$route.query.page;
          this.page = parseInt( this.$route.query.page);
          MangaService.search(this.$route.query.text || "", this.$route.query.page || 1).then(data => {
            this.results = data.Results;
            this.pages = parseInt(data.Pages);
          })
          break;
      }
    },
  },
  mounted() {
    this.type = parseInt(localStorage.type);
    this.selType = parseInt(localStorage.type);
    AuthService.getUser()
      .then((data) => {
        this.userId = data.Login.Id;
        this.$globalContainer.User = data.Users.filter(
          (el) => el.Id == this.$cookies.get("current_user")
        )[0];
        this.getSearchItems();
        this.$globalContainer.Search = this.$route.query.text;
      })
      .catch(() => {
        if (this.$cookies.get("username") != undefined) {
          AuthService.login(
            this.$cookies.get("username"),
            this.$cookies.get("password")
          )
            .then(() => {
              AuthService.getUser((data) => {
                this.userId = data.Login.Id;
                this.$globalContainer.User = data.Users.filter(
                  (el) => el.Id == this.$cookies.get("current_user")
                )[0];
                this.getSearchItems();
                this.$globalContainer.Search = this.$route.query.text;
              });
            })
            .catch(() => this.routeTo("/"));
        } else {
          this.routeTo("/");
        }
      });
  },
  watch: {
    page(newpage, oldpage){
      if(newpage != oldpage && newpage != parseInt(this.$route.query.page)){
        this.$refs.pageTop.scrollIntoView({behavior: 'smooth', block: 'end'});
        this.$router
        .push({path:'/search',query:{text: this.$route.query.text, page: newpage}});
      }
    },
    selType(newType) {
      if(localStorage.type != newType) {
        localStorage.type=newType;
        this.getSearchItems();
      }      
    },
    '$route.query.text'() {
      this.getSearchItems();
    },
    '$route.query.page'() {
      this.page = !isNaN(parseInt(this.$route.query.page))? parseInt(this.$route.query.page) : 1;
      this.getSearchItems();
    },
    type(newType) {
      if(newType == 0){
        this.$router
        .push({path:'/search',query:{text: this.$route.query.text, page: 0}});
      }
      else {
        this.$router
        .push({path:'/search',query:{text: this.$route.query.text, page: 1}});
      }
    }
  },
};
</script>




