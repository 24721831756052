import axios from 'axios';

class FilmService {

  static search(text) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/fts?text=${text}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  // TODO
  static watchAgain(limit, offset) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/film/views?limit=${limit}&offset=${offset}`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  // TODO
  static topPicks() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/film/toppicks`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  // TODO
  static newPicks() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/film/newpicks`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
  
  // TODO
  static popular() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/film/popular`, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static getDetails(url, id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/film?url=${url}&id=${id}`,{ withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
  
  static getType(url) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(`${process.env.VUE_APP_BASE_URL}/api/fts`, {url: url}, { withCredentials: true, });
        const data = res.data;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  static getVideo(url) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${process.env.VUE_APP_EXTRA_BASE_URL}/player?video=${url}`, { withCredentials: true, });
        const data = `${process.env.VUE_APP_EXTRA_BASE_URL}${res.data.src}`;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default FilmService;