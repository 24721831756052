<template>
  <v-container>
      <v-container v-show="!loading">
        <Details v-model="manga" @click-watch="watchLastChapter" @click-favourite="favourite" manga :text="'Cap: ' + nextEpisodeTitle"></Details>
      <Episodes v-model="ranges" @click-watch="ep => loadChapter(ep)" @click-view-list="viewList" @click-view="view" manga></Episodes>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>  
          <v-row>
            <v-col>
              <v-row class="pt-3 d-block d-sm-none">
                <v-col class="text-center"><v-btn outlined color="primary" @click="closeDialog()">Chiudi <v-icon>mdi-close</v-icon></v-btn></v-col>
              </v-row>
              <v-row ref="pageTop">
                <v-col cols="1"></v-col>
                <v-spacer></v-spacer>
                <v-col cols="8" xs="8" sm="8" md="4" lg="4" xl="4"  class="text-center">
                  <h1>{{manga.Title}}</h1> 
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1" class="text-center mr-3 mt-2 d-none d-sm-block"><v-btn icon outlined color="primary" @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn></v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="1" class="text-center">
                  <v-btn @click="watchPreviousChapter" icon color="primary" x-large>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="8" xs="8" sm="8" md="2" lg="2" xl="1"  class="text-center">
                  <h2>Capitolo: {{currentChapter.Number}}</h2>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-btn @click="watchNextChapter" icon color="primary" x-large>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row align="center">
                <v-spacer></v-spacer>
                <v-col cols="10" xs="10" sm="8" md="6" lg="4" xl="3">
                  <v-select outlined mandatory v-model="selectedMode" :items="modes">
                  </v-select>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col v-if="selectedMode == 'Pagine'"><ReaderPages  :chapter="currentChapter"/></v-col>
            <v-col v-if="selectedMode == 'Lista'"><ReaderList  :chapter="currentChapter"/></v-col>
          </v-row>
          <v-row>
                <v-spacer></v-spacer>
                <v-col cols="1" class="text-center">
                  <v-btn @click="watchPreviousChapter" icon color="primary" x-large>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="8" xs="8" sm="8" md="2" lg="2" xl="1"  class="text-center">
                  <h2>Capitolo: {{currentChapter.Number}}</h2>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-btn @click="watchNextChapter" icon color="primary" x-large>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-dialog>
      </v-container>
      <v-container v-show="loading">
        <v-row align="center" class="ma-0 pa-0" style="height: 90vh;" >
          <v-col class="ma-0 pa-0 text-center" align="center">
            <v-progress-circular :size="100" color="primary" indeterminate/>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ReaderPages from '../../components/ReaderPages.vue'
import ReaderList from '../../components/ReaderList.vue'
import Details from "../../components/Details.vue"
import Episodes from "../../components/Episodes.vue"
import GeneralService from "../../services/GeneralService.js"
import AuthService from "../../services/AuthService.js"
import MangaService from "../../services/MangaService.js"
import router from "../../router";

export default {
  name: "MangaWatch",
  components: {
    Details,
    Episodes,
    ReaderPages,
    ReaderList
  },
  data: () => ({
    userId: undefined,
    manga: {Title: "", Description: "", Date: "", Duration: "", Views: "", Categories: [], IsFavourite: false, IsLiked: false},
    ranges: [],
    dialog: false,
    selectedMode: "Lista",
    modes: ["Lista", "Pagine"],
    currentChapter: {Number: "", Panels: []},
    loading: true,
  }),
  computed: {
    nextEpisodeTitle() {
      let stop = false;
      let epTitle = "";
      this.ranges.forEach(el => {
        if(!stop) {
          el.Episodes.forEach((ep) => {
            if(!ep.IsViewed && stop == false) {
              epTitle = ep.Number;
              stop = true;
            }
          });
        }
      });
      return epTitle;
    }
  },
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    loadChapter(chapter){
      MangaService.panels(chapter.Url).then(data => {
        chapter.Panels = data.Panels;
        this.currentChapter = chapter;
        this.dialog = true;
        if(!chapter.IsViewed){
          chapter.IsViewed = true;
          GeneralService.addView(this.$globalContainer.User.Id, this.manga.Url, this.currentChapter.Url, "Manga");
        }
      });
    },
    watchLastChapter(){
      let stop = false;
      this.ranges.forEach(el => {
        if(!stop) {
          el.Episodes.forEach((ep) => {
            if(!ep.IsViewed && stop == false) {
              this.loadChapter(ep);
              stop = true;
            }
          });
        }
      });
    },
    watchPreviousChapter(){
      let stop = false;
      this.manga.Chapters.forEach((ep, index) => {
        if(ep.Url == this.currentChapter.Url && stop == false){
          if(index != 0){
            this.loadChapter(this.manga.Chapters[index-1]);
            stop = true;
          }
        }
      });
      this.$refs.pageTop.scrollIntoView({behavior: 'smooth', block: 'end'});
    },
    watchNextChapter(){
      let stop = false;
      this.manga.Chapters.forEach((ep, index) => {
        if(ep.Url == this.currentChapter.Url && stop == false){
          if(index != this.manga.Chapters.length-1){
            this.loadChapter(this.manga.Chapters[index+1]);
            stop = true;
          }
        }
      });
      this.$refs.pageTop.scrollIntoView({behavior: 'smooth', block: 'end'});
    },
    favourite(){
      if(this.manga.IsFavourite) GeneralService.addFavourite(this.$globalContainer.User.Id, this.manga.Url, "Manga", this.manga.Image, this.manga.Title);
      else GeneralService.removeFavourite(this.$globalContainer.User.Id, this.manga.Url, "Manga");
    },
    view(chapter){
      if(chapter.IsViewed) GeneralService.addView(this.$globalContainer.User.Id, this.manga.Url, chapter.Url, "Manga");
      else GeneralService.removeView(this.$globalContainer.User.Id, this.manga.Url, chapter.Url, "Manga");
    },
    viewList(range){
      if(range.Status) {
        range.Episodes.forEach((ep => {
          if(ep.IsViewed) GeneralService.addView(this.$globalContainer.User.Id, this.manga.Url, ep.Url, "Manga");
        }))
      }
      else {
         range.Episodes.forEach((ep => {
          if(!ep.IsViewed) GeneralService.removeView(this.$globalContainer.User.Id, this.manga.Url, ep.Url, "Manga");
        }))
      }
    },
    setLiked(){
      this.manga.Likes ++;
      this.manga.IsLiked = true;
      this.like();
      this.likeDialog = false;
    },
    closeDialog(){
      if(!this.manga.IsLiked){
        this.likeDialog = true;
      }
      this.dialog = false;
    },
    getDetails(){
      MangaService.getDetails(this.$route.query.uri, this.$globalContainer.User.Id).then(data => {
        this.manga = data;
        this.manga.Chapters.sort((a,b)=> a.Number-b.Number)
        const chunkSize = 50;
        for (let i = 0; i < this.manga.Chapters.length; i += chunkSize) {
            const chunk = this.manga.Chapters.slice(i, i + chunkSize);
            this.ranges.push({Title: `Capitoli: ${chunk[0].Number}-${chunk[chunk.length-1].Number}`, Episodes: chunk})
        }
        this.loading = false;
      })
    }
  },
  mounted() {
    this.selectedMode = localStorage.ReaderMode != undefined && localStorage.ReaderMode != null ? localStorage.ReaderMode : "Lista";
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.getDetails();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser()
          .then(data => {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.getDetails();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
  watch: {
    selectedMode(newMode){
      localStorage.ReaderMode = newMode;
    },
    '$route.query.id'() {
      if(this.userId != undefined) {
        this.manga = {Title: "", Description: "", Date: "", Duration: "", Views: "", Categories: [], IsFavourite: false, IsLiked: false},
        this.loading = true;
        MangaService.details(this.$route.query.id).then(data => {
          this.manga = data;
          this.loading = false;
        })
      }
    }
  }
};
</script>
