<template>
  <v-container>
    <v-row class="pt-6">
      <v-col>
        <h1>{{ value.Title }}</h1>
        <p class="mt-10" >{{ description }}
          <b v-if="!showDesc && value.Description.length > 1000" @click="showDesc=true" style="color: #b71c1c; cursor: pointer;">Mostra altro</b>
          <b v-if="showDesc && value.Description.length > 1000" @click="showDesc=false" style="color: #b71c1c; cursor: pointer;">Mostra meno</b>
        </p>        

        <v-row>
          <v-col>
            <h3>Data:</h3>
            <h3>Valutazione:</h3>
            <h3>Visualizzazioni:</h3>
            <br />
            <h5>{{ Array.isArray(value.Categories) ? value.Categories.join(", ") : value.Categories }}</h5>
          </v-col>
          <v-col>
            <h3>{{ value.Date.replace("-", "") }}</h3>
            <h3>{{ value.Valutation }}</h3>
            <h3>{{ value.Views }}</h3>
          </v-col>
        </v-row>
        <br />

        <v-btn color="primary" @click="$emit('click-watch')" large v-if="!mobileMode">
          <v-icon v-if="manga">mdi-book-play</v-icon>
          <v-icon v-if="!manga">mdi-play</v-icon>
          {{manga ? "Leggi" : "Guarda"}} {{text}}
        </v-btn>

         <v-btn color="primary" @click="$emit('click-watch')" large v-if="mobileMode">
          <v-icon v-if="manga">mdi-book-play</v-icon>
          <v-icon v-if="!manga">mdi-play</v-icon>
          {{text}}
        </v-btn>

        <v-btn class="ml-3" outlined icon large @click="clickFavourite()" color="secondaryLight">
          <v-icon v-if="!value.IsFavourite">mdi-playlist-plus </v-icon>
          <v-icon v-if="value.IsFavourite">mdi-playlist-check </v-icon>
        </v-btn>
        
        <v-btn v-if="value.IsViewed != undefined" class="ml-3" outlined icon large @click="clickView()" color="secondaryLight">
          <v-icon v-if="!value.IsViewed">mdi-eye </v-icon>
          <v-icon v-if="value.IsViewed">mdi-eye-off </v-icon>
        </v-btn>

      </v-col>

      <v-col>
        <v-img
          :class="`m${imageAlign}-auto`"
          width="300"
          height="400"
          :src="value.Image"
        >
        <v-chip class="mx-1 my-1 pt-1" color="secondary">
          <v-icon class="i-yellow mb-1" color="alert">mdi-star</v-icon>
          <b>{{ value.Valutation }}</b>
        </v-chip>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Details",
  props: {
    value: Object,
    text: String,
    manga: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    showDesc: false
  }),
  methods: {
    clickFavourite() {
      this.value.IsFavourite = !this.value.IsFavourite;
      this.$emit("input", this.value);
      this.$emit("click-favourite");
    },
    clickView() {
      this.value.IsViewed = !this.value.IsViewed;
      this.$emit("input", this.value);
      this.$emit("click-view");
    },
  },
  computed: {
    description() {
      if (!this.showDesc && this.value.Description.length > 1000) {
        return this.value.Description.substring(0, 1000) + "...";
      }
      else {
        return this.value.Description;
      }
    },
    imageAlign() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "x";
        case "sm":
          return "x";
        case "md":
          return "l";
        case "lg":
          return "l";
        case "xl":
          return "l";
        default:
          return 0;
      }
    },
    mobileMode(){
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    }
  },
};
</script>

<style scoped>
i.v-icon.v-icon.i-yellow {
  color: rgb(196, 196, 3);
}
</style>
