<template>
  <v-container>
      <v-container v-show="!loading">
        <Details v-model="tvSeries" @click-watch="watchLastEpisode" @click-favourite="favourite" :text="'Ep: ' + nextEpisodeTitle"></Details>
        <Episodes v-model="tvSeries.Seasons" @click-watch="ep => loadEpisode(ep)" @click-view-list="viewList" @click-view="view"></Episodes>
        <v-dialog v-model="dialog" width="80%" persistent>
          <v-card>
            <Player v-if="dialog && epUrl != 'none'" :title="tvSeries.Title" :episode="currentEpisode" @click-back="watchPreviousEpisode" @click-next="watchNextEpisode" exit @close="closeDialog()"></Player>       
          </v-card>
        </v-dialog>
      </v-container>
      <v-container v-show="loading">
        <v-row align="center" class="ma-0 pa-0" style="height: 90vh;" >
          <v-col class="ma-0 pa-0 text-center" align="center">
            <v-progress-circular :size="100" color="primary" indeterminate/>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Details from "../../components/Details.vue"
import Episodes from "../../components/Episodes.vue"
import Player from "../../components/Player.vue"
import AuthService from "../../services/AuthService.js"
import TvSeriesService from "../../services/TvSeriesService.js"
import GeneralService from "../../services/GeneralService.js"
import FilmService from "../../services/FilmService.js"
import router from "../../router";

export default {
  name: "AnimeWatch",
  components: {
    Details,
    Episodes,
    Player
  },
  data: () => ({
    userId: undefined,
    tvSeries: {Title: "", Description: "", Date: "", Duration: "", Views: "", Categories: [], Seasons: [], IsFavourite: false, IsLiked: false},
    ranges: [],
    dialog: false,
    currentEpisode: {Url: "none"},
    episodes: [],
    loading: true,
    epUrl: "none"
  }),
  computed: {
    nextEpisodeTitle() {
      let epTitle = "";
      let stop = false;
      this.tvSeries.Seasons.forEach(el => {
        if(!stop) {
          el.Episodes.forEach((ep) => {
            if(!ep.IsViewed && stop == false) {
              epTitle = ep.Number;
              stop = true;
            }
          });
        }
      });
      return epTitle;
    }
  },
  methods: {
    routeTo(link) {
      if (this.$route.path !== link) {
        router.push(link);
      } else {
        router.go();
      }
    },
    loadEpisode(episode){
      episode.Url = 'none';
      this.epUrl= "none";
      this.currentEpisode = episode;
      FilmService.getVideo(this.currentEpisode.Uid).then(data => {
        this.currentEpisode.Url = data;
        this.epUrl= data;
      })
      this.dialog = true;
      if(!episode.IsViewed){
        episode.IsViewed = true;
        GeneralService.addView(this.$globalContainer.User.Id, this.tvSeries.Url, this.currentEpisode.Uid, "TvSeries");
      }
    },
    watchLastEpisode(){
      let stop = false;
      this.tvSeries.Seasons.forEach(el => {
        if(!stop) {
          el.Episodes.forEach((ep) => {
            if(!ep.IsViewed && stop == false) {
              this.loadEpisode(ep);
              stop = true;
            }
          });
        }
      });
    },
    watchPreviousEpisode(){
      let stop = false;
      this.episodes.forEach((ep, index) => {
        if(ep.Uid == this.currentEpisode.Uid && stop == false){
          if(index != 0){
            this.loadEpisode(this.episodes[index-1]);
            stop = true;
          }
        }
      });
    },
    watchNextEpisode(){
      let stop = false;
      this.episodes.forEach((ep, index) => {
        if(ep.Uid == this.currentEpisode.Uid && stop == false){
          if(index != this.episodes.length-1){
            this.loadEpisode(this.episodes[index+1]);
            stop = true;
          }
        }
      });
    },
    favourite(){
      if(this.tvSeries.IsFavourite) GeneralService.addFavourite(this.$globalContainer.User.Id, this.tvSeries.Url, "TvSeries", this.tvSeries.Image, this.tvSeries.Title);
      else GeneralService.removeFavourite(this.$globalContainer.User.Id, this.tvSeries.Url, "TvSeries");
    },
    view(episode){
      if(episode.IsViewed) GeneralService.addView(this.$globalContainer.User.Id, this.tvSeries.Url, episode.Uid, "TvSeries");
      else GeneralService.removeView(this.$globalContainer.User.Id, this.tvSeries.Url, episode.Uid, "TvSeries");
    },
    viewList(range){
      if(range.Status) {
        range.Episodes.forEach((ep => {
          if(ep.IsViewed) GeneralService.removeView(this.$globalContainer.User.Id, this.tvSeries.Url, ep.Uid, "TvSeries");
        }))
      }
      else {
         range.Episodes.forEach((ep => {
          if(!ep.IsViewed) GeneralService.addView(this.$globalContainer.User.Id, this.tvSeries.Url, ep.Uid, "TvSeries");
        }))
      }
    },
    setLiked(){
      this.tvSeries.Likes ++;
      this.tvSeries.IsLiked = true;
      this.like();
      this.likeDialog = false;
    },
    closeDialog(){
      if(!this.tvSeries.IsLiked){
        this.likeDialog = true;
      }
      this.dialog = false;
    },
    getDetails() {
      TvSeriesService.getDetails(this.$route.query.uri, this.$globalContainer.User.Id).then(data => {
        this.tvSeries = data;
        this.tvSeries.Seasons.forEach(el => {
          el.Episodes.forEach((ep) => {
            this.episodes.push(ep);
          });
        });
        this.loading = false;
      })
    }
  },
  mounted() {
    AuthService.getUser()
    .then(data => {
      this.userId = data.Login.Id;
      this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
      this.getDetails();
    })
    .catch(() => {
      if(this.$cookies.get("username") != undefined){
        AuthService.login(this.$cookies.get("username"), this.$cookies.get("password")).then(() => {
          AuthService.getUser(data =>  {
            this.userId = data.Login.Id;
            this.$globalContainer.User = data.Users.filter((el) => el.Id == this.$cookies.get("current_user"))[0];
            this.getDetails();
          })
        })
        .catch(() => this.routeTo("/"));
      }
      else {
        this.routeTo("/");
      }
    });
  },
  watch: {
    '$route.query.id'() {
      if(this.userId != undefined) {
        this.tvSeries = {Title: "", Description: "", Date: "", Duration: "", Views: "", Categories: [], Seasons: [], IsFavourite: false, IsLiked: false};
        this.loading = true;
        TvSeriesService.details(this.$route.query.id).then(data => {
          this.tvSeries = data;
          this.loading = false;
        })
      }
    }
  },
};
</script>
