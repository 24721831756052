<template>
  <v-container class="px-5">
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel v-for="(range, i) in value" :key="i" :index="i">
          <v-expansion-panel-header>
            <v-row class="my-1" align="center">
              <v-col cols="12" md="8" lg="8" xl="8"><h2>{{ range.Title }}</h2></v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" sm="1" md="1" lg="1" xl="1" class="text-right">
                <v-btn outlined fab small @click.native.stop="clickViewList(range)" :color="checkRangeView(range) ? 'primary' : ''">
                  <v-icon v-if="!checkRangeView(range)" >mdi-eye </v-icon>
                  <v-icon v-if="checkRangeView(range)" >mdi-eye-off </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" sm="1" md="1" lg="1" xl="1" class="mr-12">
                <v-btn :class="{'disable-events': true} " class="mx-5" outlined>
                {{range.Episodes.filter(el=>el.IsViewed).length}}/{{range.Episodes.length}}
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list >
              <v-list-item @click="/*nothing*/" v-for="(episode, i) in range.Episodes" :key="i" :index="i" color="primary" >
                <v-btn color="primary" fab class="mr-4 mb-1 mt-1" @click="clickWatch(episode)">
                  <v-icon v-if="manga">mdi-book-play</v-icon>
                  <v-icon v-if="!manga">mdi-play</v-icon>
                </v-btn >

                <v-list-item-content>
                  <h3 v-if="!manga" :class="episode.IsViewed ? 'a-color-red' : ''"> Episodio: {{ episode.Number }} </h3>
                  <h3 v-if="manga" :class="episode.IsViewed ? 'a-color-red' : ''"> Capitolo: {{ episode.Number }} </h3>
                </v-list-item-content >

                <v-btn outlined fab @click="clickView(episode)" :color="episode.IsViewed ? 'primary' : ''">
                  <v-icon v-if="!episode.IsViewed" >mdi-eye </v-icon>
                  <v-icon v-if="episode.IsViewed" >mdi-eye-off </v-icon>
                </v-btn >
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Episodes",
  props: {
    value: Array,
    manga: {
      type:Boolean,
      default: false,
    }
  },
  data: () => ({}),
  methods: {
    clickView(episode) {
      episode.IsViewed = !episode.IsViewed;
      this.$emit("input", this.value);
      this.$emit("click-view", episode);
    },
    checkRangeView(range){
      return range.Episodes.filter(el => el.IsViewed == true).length > 0;
    },
    clickViewList(range){
      this.$emit("click-view-list", {Status: this.checkRangeView(range), Episodes: JSON.parse(JSON.stringify(range.Episodes))})
      if(this.checkRangeView(range)) {
        range.Episodes.forEach((ep => {
          if(ep.IsViewed) ep.IsViewed = false;
        }))
      }
      else {
         range.Episodes.forEach((ep => {
          if(!ep.IsViewed) ep.IsViewed = true;
        }))
      }
      this.$emit("input", this.value);
    },
    clickWatch(episode) {
      this.$emit("click-watch", episode);
    }
  }
};

//change color episode and icon if isviewed ez
</script>
<style scoped>
.disable-events {
  pointer-events: none
}
.a-color-red{
  color: #B71C1C
}
</style>

